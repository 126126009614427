body, html {
  background: rgb(0,0,0);
  background: linear-gradient(135deg, rgba(0,0,0,1) 0%, rgba(74,74,75,1) 100%);
}

html, body {
  margin: 0px;
  padding: 0px;
  /* width: 100%; */
  /* height: 100%; */
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
  position: fixed;
  top: 0px;
  bottom: 0px;

  left: 0px;
  right: 0px;

  background: rgb(0,0,0);
  background: linear-gradient(135deg, rgba(0,0,0,1) 0%, rgba(74,74,75,1) 100%);
}